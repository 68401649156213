import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import { useState } from "react";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Folder({ children, name, depth, setFile }) {
    
    const isSubDir = name !== "root";
    const [showSubDir, setShowSubDir] = useState(isSubDir? false : true);
    const hasNoChildren = children?.children == undefined || children?.children == null;
    const subData = hasNoChildren ? children : children?.children;
    const icon = showSubDir? <ExpandMoreIcon color="secondary" /> : <ChevronRightIcon color="secondary" />

    return (
        <>
            <ListItemButton
                key={name + depth} 
                sx={{ pl: depth }}
                color="secondary"
                onClick={() => {
                    setShowSubDir(!showSubDir)
                }}
            >
                <ListItemIcon>
                    <Stack direction={"row"}>
                        {icon}
                        <FolderRoundedIcon color="secondary" />
                    </Stack>
                </ListItemIcon>
                <ListItemText primary={name} />
            </ListItemButton>
            <Collapse
                in={showSubDir}
                key={name + depth + "Collapse"}
            >
                <Stack gap={1}>
                    {
                        subData.map(entry => {
                            const _type = entry?.children !== undefined ? "directory" : "file";
                            const _name = entry?.name;

                            if (_type === "directory") {
                                return (
                                    <Folder
                                        name={_name}
                                        children={entry?.children}
                                        depth={depth + 3}
                                        setFile={setFile}
                                    />
                                )
                            }

                            return <File 
                                        name={_name} 
                                        depth={depth + 3} 
                                        src={entry?.src}
                                        setFile={setFile}    
                                    />
                        })
                    }
                </Stack>
            </Collapse>
        </>
    )
}

function File({ src = "", name, depth, setFile }) {
    return (
        <ListItemButton 
            sx={{ pl: depth }} 
            key={name + depth}
            onClick={() => setFile(src)}
        >
            <ListItemIcon>
                <DescriptionRoundedIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={name || "None"} />
        </ListItemButton>
    )
}

export default function GeneralTabs({ data, setFile, depth = 1 }) {

    return (
        <List>
            <Folder 
                children={data}
                depth={depth}
                name={data?.name}
                setFile={setFile}
            />
        </List>
    )
}
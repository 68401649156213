import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import { ProjectPage } from "./Components/ProjectPage";
import { createClient } from '@supabase/supabase-js'
import { ImageSegmentation } from "./Components/Pages/ImageSegmentation";
import { ConnectedComponets } from "./Components/Pages/ConnectedComponets";
import { GeometricFeatureComputation } from "./Components/Pages/GeometricFeatureComputation";
import { CornerDetection } from "./Components/Pages/CornerDetection";
import { EdgeDetection } from "./Components/Pages/EdgeDetection";
import { HistogramEqualization } from "./Components/Pages/HistogramEqualization";
import ProjectNotAvailable from "./Components/Pages/Error/ProjectNotAvailable";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SocketFileTransfer from "./Components/Pages/Networking/SocketFileTransfer";
import EnhancedClientCommunication from "./Components/Pages/Networking/EnhancedClientCommunication";


const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const queryClient = new QueryClient();

export const supabase = createClient(`${supabaseUrl}`, supabaseKey)
export const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <ProjectNotAvailable />,
    },

    {
        path: "/Project/Image Segmentation",
      element: 
      <QueryClientProvider client={queryClient}>
          <ImageSegmentation />
      </QueryClientProvider>
    },

    {
      path: "/Project/Connected Components",
      element: 
      <QueryClientProvider client={queryClient}>
        <ConnectedComponets />
      </QueryClientProvider>
      
    },

    {
      path: "/Project/Geo-Feature Computation",
      element: 
      <QueryClientProvider client={queryClient}>
        <GeometricFeatureComputation />
      </QueryClientProvider>
    },

    {
      path: "/Project/Corner Detection",
      element: 
      <QueryClientProvider client={queryClient}>
        <CornerDetection />
      </QueryClientProvider>
    },

    {
      path: "/Project/Edge Detection",
      element: 
      <QueryClientProvider client={queryClient}>
        <EdgeDetection />
      </QueryClientProvider>
    },

    {
      path: "/Project/Histogram Equalization",
      element: 
      <QueryClientProvider client={queryClient}>
        <HistogramEqualization />
      </QueryClientProvider>
    },

    {
      path: "/Project/Socket File Transfer",
      element: 
      <QueryClientProvider client={queryClient}>
        <SocketFileTransfer />
      </QueryClientProvider>
    },

    {
      path: "/Project/Enhanced CLient Communication",
      element: 
      <QueryClientProvider client={queryClient}>
        <EnhancedClientCommunication />
      </QueryClientProvider>
    },

  ]);


import { Avatar, Box, Button, Collapse, Grow, Paper, Stack, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid2 from "@mui/material/Unstable_Grid2";
import GitHubIcon from '@mui/icons-material/GitHub';
import { useState } from "react";


export default function Contributor({ img, name, role, githubLink }) {
    const [ elevation, setElevation ] = useState(4);
    const [ iconArea, setIconArea ] = useState(56);
    const [ showContLinks, setShowContLinks ] = useState(false);
    const [ show, setShow ] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    
    const expandCard = () => {
        setElevation(8)
        setShowContLinks(true)
        // setIconArea(67)
    }
    
    const shrinkCard = () => {
        setElevation(4)
        setShowContLinks(false)
        // setIconArea(56)
    }
    
    if (img) {
        
        if (isSmallScreen) {

            return (<>
            
            </>);
        }


        return (<>
            <Grow
                timeout={800}
                in={true}
            >
                <Box
                    key={1}
                    onMouseEnter={expandCard}
                    onMouseLeave={shrinkCard}
                    sx={{
                        borderRadius: "10px",
                    }}
                >
                    <Paper
                        elevation={elevation}
                        sx={{
                            padding: "0.5rem"
                        }}
                    >
                        <Grid2 container spacing={3}>
                            <Grid2 sx={4}>
                                <Avatar alt={name} src={img} sx={{ width: iconArea, height: iconArea }}/>
                            </Grid2>
                            <Grid2 sx={8}>
                                <Stack>
                                    <Typography fontWeight={"600"}>{role}</Typography>
                                    <Typography fontWeight={"200"}>{name}</Typography>
                                    
                                </Stack>
                            </Grid2>
                            <Grid2 sx={8}>
                                <Collapse orientation="horizontal" in={showContLinks}>
                                    <Stack direction={"row"}>
                                        <Button 
                                            variant="outlined" 
                                            size="small" 
                                            startIcon={<GitHubIcon />}
                                            onClick={() => window.open(githubLink)}
                                        >
                                            <Typography fontWeight={"200"} fontSize={"small"}>GitHub</Typography>
                                        </Button>    
                                    </Stack>                                
                                </Collapse>
                            </Grid2>
                        </Grid2>
                    </Paper>
                </Box>
            </Grow>
        </>);
    }
    return <></>
}
import { useQuery } from "@tanstack/react-query";
import { Octokit } from "octokit";

const githubAPIVersion = '2022-11-28';
export default function useGetContributors({ repo, owner }) {
    const query = useQuery({
        queryKey: [repo, owner],
        queryFn: async () => {
            const oktokitClient = new Octokit({
                auth: process.env.REACT_APP_GITHUB_KEY
            })

            const response = await oktokitClient.request(`GET /repos/${owner}/${repo}/collaborators`, {
                owner: owner,
                repo: repo,
                headers: {
                  'X-GitHub-Api-Version': githubAPIVersion
                }
              })
            
            return response?.data?.map(contributor => ({
                img: contributor.avatar_url,
                name: contributor.login,
                githubLink: contributor.html_url,
                role: "Engineer"
            }))
        }
    })

    return query?.data
}
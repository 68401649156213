import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

export default function Architecture({ children }) {

    const [isMouseOver, setIsMouseOver] = useState(false);
    return (
        <Box
            onMouseEnter={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
        >
            <Paper
                elevation={isMouseOver ? 6 : 4}
            >
                <Stack spacing={2} padding={"1rem"}>
                    <Stack spacing={1} direction={"row"}>
                        <Typography variant="h6" fontWeight="normal">Architecture</Typography>
                        {/* <SettingsSuggestIcon color="secondary" /> */}
                    </Stack>
                    <Divider />
                    <Box
                        sx={{
                            overflow: "hidden"
                        }}
                    >
                        {children}
                    </Box>
                </Stack>
            </Paper>
        </Box>
    )
}
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import GeneralTabs from "./GeneralTabs";
import { useState } from "react";
import FindStartFile from "./FindStartFile";


export function ProjectSourceCodeV2({ directory = {} , elevation = 4 }){
    
    // init is the first file shown, when no file is selected
    // const startFile = directory?.init === undefined? directory?.children[0].src : directory?.init;
    const startFile = FindStartFile(directory);
    let [ file, setFile ] = useState(startFile);
    console.log("file: ", file)

    return (<>
        <Box>
            <Paper
                elevation={elevation}
            >

                <Box
                    padding={"1em"}
                >
                    <Typography variant="h6" fontWeight={"400"}>Source Code</Typography>
                    <Divider />
                </Box>
                {/* Body */}
                <Box>
                    <Grid container>

                        <Grid item md={3} lg={3}>
                            <GeneralTabs 
                                data={directory}
                                setFile={setFile}
                            />     
                        </Grid>
                        <Grid item md={9} lg={9}>
                            <Box
                                key={file}
                                className={"sourceParent"}
                                paddingLeft={"2vw"}
                                paddingRight={"1vw"}
                            >
                                <iframe frameborder="0" scrolling="yes" style={{ width: "100%", height: "1114px" }} allow="clipboard-write" src={file}></iframe>
                            </Box>
                        </Grid>

                    </Grid>
                </Box>
            </Paper>
        </Box>
    </>)
}
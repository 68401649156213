import { Box } from "@mui/material";
import Contributor from "./Contributor";

// Update contributors to utilize Girhub api call for metadata
export default function ContributorBar({ contributors }) {

    return (<>
        <Box
            sx={{
                display: "flex",
                justifyContent: "start",
                alignContent: "start"
            }}
        >
            {
                contributors && (
                    contributors.map(data => (
                        
                        <Contributor 
                            img={data.img}
                            role={data.role}
                            name={data.name}
                            githubLink={data.githubLink}
                        />
                    ))
                )
            }
        </Box>
    </>);
}
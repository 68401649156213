
/**
 * @abstract Given a file directory returns the first file it finds.
 * Assumes each file directory contains at least 1 file. 
 * @param {Record} Root 
 * @returns {string} src 
 */
export default function FindStartFile( Root ) {
    const isFile = Root?.src !== undefined;
    if (isFile) {
        return Root?.src;
    }

    for (let i = 0; i < Root?.children?.length; ++i) {
        let src = FindStartFile(Root?.children[i]);
        if (src !== undefined || src !== null) {
            return src;
        }
    }
}

